<template>
  <div>
    <div class="wrap-container page_login" ref="page_login" v-if="!weixin">
      <div class="wrap">
        <div class="logo-title">
          <div class="logo">
            <img :src="logo" ref="logo" alt="logo" /><span
              style="font-size: 26px;margin-left:12px"
            >
              监测平台</span
            >
          </div>
        </div>
        <div class="content-container" ref="contentContainer">
          <div class="changeSize" ref="loginSideImg"></div>
          <div class="form">
            <el-form
              ref="form"
              :model="form"
              label-width="40px"
              class="login-form"
              label-position="top"
            >
              <div class="login-type-select">
                <p
                  class="login-title"
                  :class="form.type === '0' ? 'login-title-selected' : ''"
                  @click="form.type = '0'"
                >
                  密码登录
                </p>
                <!-- <p
                  class="login-title"
                  :class="form.type === '1' ? 'login-title-selected' : ''"
                  @click="form.type = '1'"
                >
                  短信登录
                </p> -->
              </div>
              <div v-if="form.type === '0'">
                <el-form-item>
                  <el-input
                    v-model="form.username"
                    placeholder="请输入用户名"
                    prefix-icon="el-icon-user"
                  ></el-input>
                </el-form-item>
                <el-form-item class="passwordIpt" style="margin-top: 8%">
                  <el-input
                    :type="pwdConf.type"
                    v-model="form.password"
                    prefix-icon="el-icon-lock"
                    placeholder="请输入密码"
                  >
                    <svg-icon
                      :icon-class="pwdConf.icon"
                      slot="suffix"
                      @click="pwdVisible = !pwdVisible"
                    />
                  </el-input>
                </el-form-item>

                <el-form-item>
                  <el-button class="login-in" type="primary" @click="userLogin"
                    >登录
                  </el-button>
                </el-form-item>
              </div>
              <div v-else>
                <el-form-item label="手机号">
                  <el-input
                    v-model="form.phone"
                    placeholder="请输入手机号"
                  ></el-input>
                </el-form-item>
                <el-form-item label="验证码">
                  <el-input v-model="form.code" placeholder="请输入验证码">
                    <el-button
                      slot="append"
                      :loading="isSending"
                      @click="getVerifyCode"
                      :disabled="disableVerifyCodeBtn"
                      >{{
                        countDown === '获取验证码'
                          ? countDown
                          : countDown + '秒后重试'
                      }}</el-button
                    >
                  </el-input>
                </el-form-item>

                <el-form-item>
                  <el-button class="login-in" type="primary" @click="userLogin"
                    >登录
                  </el-button>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
      </div>
      <div class="foot-beian" ref="footBeian">
        <a class="foot-link" href="https://beian.miit.gov.cn"
          >京ICP备17028721号-3
        </a>
      </div>
    </div>
    <div class="weixin-tip-box" v-if="weixin">
      <div class="triangle_border_up">
        <span></span>
      </div>
      <div class="weixin-tip">
        <p>请点击右上角</p>
        <p class="content">选择"浏览器中打开"</p>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie';
import api from '@/api/module/user';
import { isWeixin } from '@/utils/utils';

export default {
  data() {
    return {
      /** 密码可见 */
      pwdVisible: false,
      setUserInfoObj: {
        agencyId: '',
        agencyCode: '',
        agencyName: '',
        parentId: null,
      },
      form: {
        username: '',
        password: '',
        phone: '',
        code: '',
        type: '0',
        rememberMe: false,
      },
      showLogo: false,
      logo: require('@/assets/logo/logo.png'),
      title: '',
      disableVerifyCodeBtn: false,
      countDown: '获取验证码',
      countDownInterval: '',
      isSending: false,
      sizeStyle: 'logoBigImg',
      weixin: false,
    };
  },
  computed: {
    pwdConf: function () {
      return this.pwdVisible
        ? { icon: 'login_eyeShow', type: 'text' }
        : { icon: 'login_eyeHide', type: 'password' };
    },
  },
  created: function () {
    // 如果用户登录，就回到上一页
    // if (window.sessionStorage.getItem('userInfo')) {
    //   let history = this.$router.history.base;
    //   this.$router.go(-1);
    // }
    const that = this;
    document.onkeyup = function (ev) {
      let { key, keyCode } = ev;
      if (key === 'Enter' || keyCode === 13) {
        that.userLogin();
      }
    };
  },
  mounted() {
    this.isWeixin();
  },
  watch: {},
  destroyed: function () {
    document.onkeyup = null;
    clearInterval(this.countDownInterval);
  },
  methods: {
    userLogin() {
      if (this.form.type === '0' && !this.form.username) {
        this.$message.error('请输入用户名！');
        return;
      }
      if (this.form.type === '0' && !this.form.password) {
        this.$message.error('请输入密码！');
        return;
      }
      if (this.form.type === '1' && !this.form.phone) {
        this.$message.error('请输入手机号！');
        return;
      }
      if (this.form.type === '1' && !this.form.code) {
        this.$message.error('请输入验证码！');
        return;
      }
      if (this.form.type === '0') {
        this.$api.loginIn
          .login({
            username: this.form.username,
            password: this.form.password,
            type: this.form.type,
            rememberMe: this.form.rememberMe,
          })
          .then((res) => {
            if (res.code === 0) {
              this.getUserInfo();
            }
          });
      } else if (this.form.type === '1') {
        this.$api.loginIn
          .login({
            phone: this.form.phone,
            code: this.form.code,
            type: this.form.type,
            rememberMe: this.form.rememberMe,
          })
          .then((res) => {
            if (res.code === 0) {
              this.getUserInfo();
            }
          });
      }
    },
    // 获取用户权限
    getUserInfo() {
      api.getUser().then((res) => {
        Cookies.set('Token', res.data.token); // 登录成功后将token存储在cookie之中
        // 记录登录的地址以便退出登录时push
        res.data.fullPath = this.$route.fullPath;
        window.sessionStorage.setItem('userInfo', JSON.stringify(res.data));
        if (!res.data.menu || res.data.menu.length <= 0) {
          this.$message({
            message: '该账号角色暂无权限，请联系管理员',
            type: 'error',
          });
          return;
        }
        let url = '';
        // push到二级菜单第一项
        if (res.data.menu[0].children.length) {
          url = res.data.menu[0].children[0].url;
        } else {
          // push 到一级菜单
          url = res.data.menu[0].url;
        }
        this.$router.push({ path: url });
      });
    },

    getVerifyCode() {
      this.isSending = true;
      if (this.countDown !== '获取验证码') {
        this.$message.error('请在' + this.countDown + '秒后重试');
        return;
      }
      if (!/^1(3|4|5|7|8)\d{9}$/.test(this.form.phone)) {
        this.$message.error('请输入正确的手机号');
        return;
      }
      this.countDown = 60;
      this.$api.loginIn.sendVerifyCode(this.form.phone).then((res) => {
        this.isSending = false;
        this.countDownInterval = setInterval(() => {
          if (this.countDown > 0) {
            this.countDown--;
          } else {
            this.countDown = '获取验证码';
            clearInterval(this.countDownInterval);
          }
        }, 1000);
        if (res.code === 0) {
          this.$message.success('发送成功');
        }
      });
    },
    /**
     * 使用vue实现的防抖函数
     */
    debounce(func, wait = 3000) {
      if (this.timeout || this.isLoading || this.isSaving) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        func();
      }, wait);
    },
    isWeixin() {
      var is_weixin = isWeixin();
      if (is_weixin) {
        this.weixin = true;
      } else {
        this.weixin = false;
      }
    },
  },
};
</script>

<style lang="scss">
.page_login {
  .passwordIpt {
    .el-input__suffix {
      width: 28px;
    }
  }
}
</style>
<style scoped lang="scss">
.el-form {
  .el-input {
    display: inline-table;
  }
}

.wrap-container {
  height: 100%;
  width: 100%;
  box-sizing: content-box;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #cfdfee;
  .wrap {
    position: absolute;
    // top: 3rem;
    width: 100%;
    border-radius: 10px;
    .logo{
      display: flex;
      align-items: stretch;
      height: 40px;
      width: 100%;
    }
    .logo-title {
      position: absolute;
      width: 100%;
      margin-left: 6%;
      margin-top: 5%;
    }
  }
  .content-container {
    display: flex;
    .form {
      position: absolute;
      left: 56%;
      top: 18%;
      width: 30%;
      height: 45%;
      padding: 4%;
      border-radius: 10px;
      background-color: #fff;
      .login-type-select {
        display: flex;
        justify-content: space-around;
        margin-bottom: 8%;

        .login-title {
          text-align: center;
          // margin-bottom: 1rem;
          font-size: 1rem;
          padding: 5px 8px;
          //font-weight: bold;
          cursor: pointer;
        }

        .login-title-selected {
          text-align: center;
          // margin-bottom: 1rem;
          font-size: 1rem;
          padding: 5px 8px;
          //font-weight: bold;
          color: #4787ff;
          border-bottom: 4px solid #4787ff;
        }
      }

      .login-in {
        text-align: center;
        letter-spacing: 10px;
        font-size: 14px;
        background-color: #4387ff;
        margin-top: 7%;
        width: 100%;
        float: right;
        height: 40px;
        border: 0;
      }
    }
  }
}
.changeSize {
  box-sizing: border-box;
  width: 100%;
  height: 120vh;
  background: url('~@/assets/images/backgroundImg/bg.png') center center
    no-repeat;
  background-size: 145% 166%;
}
.el-button--primary {
  color: #ffffff;
  background-color: #2672cc;
  border-color: #2672cc;
}
.foot-beian {
  position: absolute;
  bottom: 1%;
  left: 50%;
  transform: translate(-50%, 0);
  .foot-link {
    outline: none;
    background: none;
    text-decoration: none;
    color: #000;
    font-size: 12px;
  }
}
.weixin-tip-box {
  position: fixed;
  right: 0;
  top: 4px;
  align-items: center;
  width: 100%;
  height: 100%;
}
.weixin-tip {
  background: #fff;
  z-index: 100;
  padding: 8px;
  border-radius: 8px;
  margin-right: 8px;
}
.weixin-tip p {
  text-align: center;
  font-size: 14px;
  color: #333;
}
.weixin-tip p.content {
  text-align: center;
  font-size: 14px;
}
.triangle_border_up {
  width: 0;
  height: 0;
  border-width: 0 6px 12px;
  border-style: solid;
  border-color: transparent transparent #fff;
  /*透明 透明  灰*/
  margin-left: 86rem;
}
</style>
